import service from '@/utils/request';

// @Tags RmMenu
// @Summary 创建RmMenu
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenu true "创建RmMenu"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmMenu/createRmMenu [post]
export var createRmMenu = function createRmMenu(data) {
  return service({
    url: "/rmMenu/createRmMenu",
    method: 'post',
    data: data
  });
};

// @Tags RmMenu
// @Summary 删除RmMenu
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenu true "删除RmMenu"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmMenu/deleteRmMenu [delete]
export var deleteRmMenu = function deleteRmMenu(data) {
  return service({
    url: "/rmMenu/deleteRmMenu",
    method: 'delete',
    data: data
  });
};

// @Tags RmMenu
// @Summary 删除RmMenu
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmMenu"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmMenu/deleteRmMenu [delete]
export var deleteRmMenuByIds = function deleteRmMenuByIds(data) {
  return service({
    url: "/rmMenu/deleteRmMenuByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmMenu
// @Summary 更新RmMenu
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenu true "更新RmMenu"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmMenu/updateRmMenu [put]
export var updateRmMenu = function updateRmMenu(data) {
  return service({
    url: "/rmMenu/updateRmMenu",
    method: 'put',
    data: data
  });
};

// @Tags RmMenu
// @Summary 用id查询RmMenu
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmMenu true "用id查询RmMenu"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmMenu/findRmMenu [get]
export var findRmMenu = function findRmMenu(params) {
  return service({
    url: "/rmMenu/findRmMenu",
    method: 'get',
    params: params
  });
};

// @Tags RmMenu
// @Summary 分页获取RmMenu列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmMenu列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmMenu/getRmMenuList [get]
export var getRmMenuList = function getRmMenuList(params) {
  return service({
    url: "/rmMenu/getRmMenuList",
    method: 'get',
    params: params
  });
};
export var getRmMenuTreeList = function getRmMenuTreeList(params) {
  return service({
    url: "/rmMenu/getRmMenuTreeList",
    method: 'get',
    params: params
  });
};
export var getGroupRmMenuLstByGroupId = function getGroupRmMenuLstByGroupId(data) {
  return service({
    url: "/rmMenu/getGroupRmMenuLstByGroupId",
    method: 'post',
    data: data
  });
};
export var getAllRmMenus = function getAllRmMenus(data) {
  return service({
    url: "/rmMenu/getAllRmMenus",
    method: 'post',
    data: data
  });
};
export var getRmMenuLstByUserId = function getRmMenuLstByUserId(data) {
  return service({
    url: "/rmMenu/getRmMenuLstByUserId",
    method: 'get',
    params: data
  });
};
export var getRmMenuTreeListByUserID = function getRmMenuTreeListByUserID(data) {
  return service({
    url: "/rmMenu/getRmMenuTreeListByUserID",
    method: 'get',
    params: data
  });
};